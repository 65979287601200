@font-face {
  font-family: 'superstar';
  src: local('superstar'), url('./fonts/superstar.ttf') format('truetype');
}

@font-face {
  font-family: 'TOMMY';
  src: local('TOMMY'), url('./fonts/tommy.otf') format('opentype');
}

@font-face {
  font-family: 'TOMMY-L';
  src: local('TOMMY-L'), url('./fonts/tommy-l.otf') format('opentype');
}

@font-face {
  font-family: 'TOMMY-R';
  src: local('TOMMY-R'), url('./fonts/tommy-r.otf') format('opentype');
}

@font-face {
  font-family: 'TOMMY-O';
  src: local('TOMMY-O'), url('./fonts/tommy-o.otf') format('opentype');
}

.amount-input {
  color: #fcfcfc;
  width: 100%;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;

  background-color: inherit;
  border-width: 0px;
  border-radius: 0;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.animated-underline {
  cursor: pointer;
  color: #fcfcfc;
  background-image: linear-gradient(#00000000, #00000000), linear-gradient(to right, #e646e6, #930993);
  background-size: 100% 2px, 0 2px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
}

.animated-underline-burn {
  cursor: pointer;
  color: #fcfcfc;
  background-image: linear-gradient(#00000000, #00000000), linear-gradient(to right, #e67f47, #dd6727);
  background-size: 100% 2px, 0 2px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
}

@media (prefers-reduced-motion: no-preference) {
  .animated-underline,
  .animated-underline-burn {
    transition: 0.3s ease;
    transition-property: background-size, color, background-color, border-color;
  }
}
.animated-underline:hover,
.animated-underline:focus-visible,
.animated-underline-burn:hover,
.animated-underline-burn:focus-visible {
  background-size: 0 2px, 100% 2px;
}

.nav-menu-item {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: none;
  padding-top: 4px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fcfcfc;
}

.nav-menu-item:hover {
  color: #e646e6;
}

.no-cursor {
  cursor: default;
}

.animated-button {
  font-family: 'TOMMY-R';
  text-align: center;
  justify-content: center;
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;
  border: 0;
  color: #fcfcfc;
  padding: 8px 12px 8px 12px;
  text-transform: uppercase;
}

.animated-hover-1 {
  position: absolute;
  transition: left 200ms;
  transform: rotate(5deg);
  left: -42%;
  opacity: 0.2;
  z-index: 10;
  height: 120%;
}

.animated-hover-2 {
  position: absolute;
  transition: left 200ms;
  left: -42%;
  top: -5px;
  z-index: 10;
  height: 120%;
  width: 5px;
  transform: rotate(22deg);
  background-color: #e646e659;
}

.animated-hover-3 {
  position: absolute;
  transition: left 200ms;
  left: -42%;
  top: -5px;
  z-index: 10;
  height: 120%;
  width: 8px;
  transform: rotate(22deg);
  background-color: #e646e659;
}

.button-hover:hover .animated-hover-1 {
  left: 82%;
}
.button-hover:hover .animated-hover-2 {
  left: 60%;
}
.button-hover:hover .animated-hover-3 {
  left: 68%;
}

.secondary-button {
  font-family: 'TOMMY-R';
  cursor: pointer;
  border: 0;
  font-weight: 900;
  font-size: 14px !important;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fcfcfc;
  height: 50px;
  background: #930993;
  width: 100%;
  border-radius: 5px;
}

.secondary-button:hover {
  background: #6f076f;
}

.primary-button {
  font-family: 'TOMMY-R';
  cursor: pointer;
  border: 0;
  font-weight: 900;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fcfcfc;
  height: 50px;
  width: 100%;
  background: #e646e6;
  border-radius: 5px;
}

.primary-button:hover {
  background: #c03bc0;
}

.primary-button:disabled {
  background: #cfd5f3;
  cursor: not-allowed;
}

.secondary-button:disabled {
  background: #d7d7d7;
  cursor: not-allowed;
}

.uppercase {
  text-transform: uppercase;
}
.tabs {
  font-weight: 700;
  font-size: 18px;
}
.tabItem {
  cursor: pointer;
  color: #9aa4da;
  font-weight: 700;
  font-size: 18px;
}
.tabItemActive {
  text-decoration: underline;
  text-underline-offset: 10px;
  color: #fcfcfc;
}
.tabDetailsItem {
  cursor: pointer;
  color: #d8d8d8;
  font-weight: 700;
  font-size: 16px;
}

.tabDetailsItemActive {
  font-weight: 700;
  color: #e646e6;
  border-radius: 5px;
  border-bottom: 2px solid #e646e6;
}

.accordion {
  border-bottom: 3px solid transparent;
  border-radius: 10px !important;
  margin-top: 20px !important;
}
.accordion:hover {
  transition: border-bottom 0.35s;
  border-bottom: 3px solid #e646e6;
}

.lineLogo {
  vertical-align: middle;
  margin-right: 20px;
}
.lineLabel {
  color: #d8d8d8;
  font-size: 14px;
}
.lineDescription {
  color: #d8d8d8;
  font-size: 12px;
}
.lineValue {
  color: #fcfcfc;
  font-size: 16px;
}
.printing {
  font-weight: 700;
  color: rgb(19, 175, 19);
}
.not-printing {
  color: rgb(174, 20, 20);
}

.dropdown {
  cursor: pointer;
}
.lineName {
  font-size: 18px;
}

.dashboard-token-box {
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: 4px;
}

.dashboard-token-box-inner {
  padding: 10px;
  text-align: center;
}

.node-lineDetailsBox {
  min-height: 320px;
  background-color: #e9e9e921;
  border-radius: 10px;
}
.lineDetailsBox {
  background-color: #e9e9e921;
  border-radius: 10px;
}
.node-inputDetailsBox {
  margin-top: 25px;
}
.inputDetailsBox {
  margin-top: 50px;
}
.inputDetailsBoxInner {
  padding: 16px;
  background-color: #2b2d3c66;
  border-radius: 10px;
}
.lineValueDeposited {
  color: #e646e6;
  font-size: 16px;
}
.rewardTokenAmount {
  font-weight: 700;
  font-size: 21px;
  line-height: 28px;
  text-align: center;
  color: #fcfcfc;
}
.rewardTokenValue {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #e646e6;
}
.sharesLeftValue {
  font-size: 12px;
  text-align: center;
}

.addRemoveLiquidity {
  text-decoration: underline;
  text-underline-offset: 10px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.05em;
}
.max-button {
  cursor: pointer;
  font-weight: 700;
}

.rewards-per-day {
  font-weight: 700;
  color: #fcfcfc;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.05em;
}
.pending-rewards {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fcfcfc;
}
.color-secondary {
  color: #d8d8d8;
}
.line-details-inner {
  padding: 35px 35px 0 35px;
  min-height: 300px;
}

.box-price-of-one {
  font-size: 14px;
  text-align: right;
}

.node-line-details-inner {
  padding: 35px 35px 0 35px;
  min-height: 235px;
}

.press-line-details-inner {
  padding: 35px 35px 0 35px;
  min-height: 300px;
}

@media screen and (max-width: 600px) {
  .press-line-details-inner,
  .node-line-details-inner,
  .line-details-inner {
    padding: 17px 17px 0 17px;
  }
}
.balance {
  padding-left: 3px;
  font-size: 14px;
}
.slider-value {
  color: #fcfcfc;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
}
.slider-box {
  padding-left: 5px;
  padding-right: 5px;
}

.time-left {
  color: #aa0faa;
}
.withdraw-time-left {
  color: #930993;
}

.MuiSelect-icon {
  color: white !important;
}
.MuiSelect-select {
  color: #fcfcfc !important;
}

.MuiMenu-list {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.statBox {
  min-height: 60px;
  background-color: #e9e9e921;
  border-radius: 10px;
}

.statBoxInner {
  padding: 15px;
  text-align: center;
  font-size: 14px;
}

.MuiTypography-h4 {
  font-size: 2.425rem !important;
}

.MuiTypography-h5 {
  font-size: 1.35rem !important;
}

.MuiTypography-h6 {
  font-size: 1.05rem !important;
}

.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4 {
  font-family: 'superstar' !important;
}

.MuiTypography-h5,
.MuiTypography-h6,
.MuiAlert-root,
.MuiTypography-body1 {
  font-family: 'TOMMY-R' !important;
}

body,
html,
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: 'TOMMY-R', sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-y: auto;
  scroll-behavior: smooth;
  height: 100%;
}

/* .makeStyles-paper-11, */
/* .makeStyles-paper-11 h2, */
/* .MuiTypography-body1,
.MuiPaper-root, */
h2 {
  color: #ccf;
  font-size: 2.5em;
}

body {
  font-size: 1em;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center center !important;
  background-attachment: fixed !important;
  background-size: cover !important;
}

.new-ribbon {
  width: 60px;
  margin-top: -6px;
  margin-left: -6px;
}

.hot-ribbon {
  width: 60px;
  height: 70px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'TOMMY', 'monospace';
  text-transform: uppercase;
  color: #fff;
}

.MuiToolbar-regular {
  color: #000;
}

.nav-logo {
  transition: transform 0.3s;
}

.nav-logo:hover {
  transform: scale(1.025);
}

.MuiListItemButton-root:not(:hover) {
  transition: transform 0.3s;
}

.MuiListItemButton-root:hover {
  transform: scale(1.045);
  transition: transform 0.3s;
}

.MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover,
.MuiListItem-button:hover,
.MuiButtonBase-root:hover {
  color: #fff !important;
}
.MuiButton-containedPrimary {
  background: #930993 !important;
}

.tvl {
  font-size: 2.5rem;
}

.background-blur {
  position: fixed;
  z-index: -999;
  width: 100%;
  height: 100%;
  left: 0%;
  right: 0%;
  max-width: 100%;
}

.wallet-token-value {
  color: #e646e6;
  font-size: 14px;
}

.wallet-token-balance {
  font-size: 1.4rem;
}
@media screen and (max-width: 1300px) {
  .wallet-token-balance {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 1150px) {
  .wallet-token-balance {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 660px) {
  .tvl {
    font-size: 4rem;
  }
}

@media screen and (max-width: 482px) {
  .tvl {
    font-size: 3.5rem;
  }
  .wallet-token-balance {
    font-size: 0.8rem;
  }

  .MuiTypography-h4 {
    font-size: 1.925rem !important;
  }

  .MuiTypography-h5 {
    font-size: 1.15rem !important;
  }
}

@media screen and (max-width: 430px) {
  .tvl {
    font-size: 3rem;
  }
  .wallet-token-balance {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 376px) {
  .tvl {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 359px) {
  .tvl {
    font-size: 1rem;
  }
}

.MuiButton-containedPrimary:hover {
  background: #d19aff !important;
}

code {
  font-family: 'TOMMY', monospace;
}

.menu-item {
  border-bottom: 0 !important;
  color: white !important;
  fill: white !important;
  margin-left: 0 !important;
  text-decoration: none !important;
  transition: padding-left 200ms;
}

.menu-item:hover {
  font-weight: bold !important;
}

.price-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 1px solid rgba(156, 156, 156, 0.18);
}

.price-item {
  padding: 7px;
}

.price-item img {
  vertical-align: middle;
}

.token-price {
  color: white;
  font-size: 0.8rem;
  text-decoration: none;
  margin-top: 0;
  margin-bottom: 0;
}

.token-price:hover {
  color: #e646e6;
}

.dashboard-top-pool-category {
  color: #e646e6;
}

.dashboard-top-pool-value {
  color: #fcfcfc;
  font-weight: 700;
}

.dashboard-top-reward-label {
  color: #f9b857;
  font-size: 14px;
}

.dashboard-top-reward-value {
  color: #fcfcfc;
  font-size: 14px;
}

.p2 {
  padding: 10px 15px 10px 15px;
}

.button-first {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.button-last {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dashboard-tab-item {
  cursor: pointer;
  color: #e646e6;
  background-color: rgba(0, 0, 0, 0.37);
  font-weight: 500;
  font-size: 16px;
}
.dashboard-tab-item-active {
  cursor: pointer;
  background-color: #930993;
  color: #fcfcfc;
}

.text-decoration-none {
  text-decoration: none;
}

.wallet-button {
  color: #fff;
  font-family: 'monospace', sans-serif !important;
  font-size: 1.2rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}
.wallet-button:hover {
  color: #930993 !important;
}

.navLink {
  color: #fff !important;
}

.navLink:hover {
  color: #ccf !important;
}

.shinyButton {
  height: 40px;
  font-family: 'TOMMY-R' !important;
  padding: 7px !important;
  background-color: rgb(147, 9, 147) !important;
  font-size: 14px !important;
  color: #fff !important;
  border-radius: 5px !important;
}

.shinyButton-secondary {
  height: 40px;
  font-family: 'TOMMY-R' !important;
  padding: 7px !important;
  background-color: rgb(98, 98, 98) !important;
  font-size: 14px !important;
  color: #fff !important;
  border-radius: 5px !important;
}

.shinyButton:hover {
  background: rgb(130, 8, 130);
}

.button-63:active,
.button-63:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-63 {
    font-size: 24px;
    min-width: 196px;
  }
}
.full-width {
  width: 100% !important;
}

.welcome-text {
  font-size: 3.8rem;
  font-family: 'Tommy';

  color: #fff !important;
  overflow: visible !important;
}

.front-text-tvl {
  font-family: 'TOMMY';
  font-size: 5.5rem;
  text-shadow: 0 0 1vw #6e256c, 0 0 3vw #6e256c, 0 0 20vw #6e256c, 0 0 10vw #6e256c, 0 0 0.4vw #e647e6,
    0vw 0vw 0vw #5b2959;
  color: #e647e6;
  overflow: visible;
}

.front-text-top {
  font-size: 2.5rem;
  font-family: 'TOMMY-R';
  /* text-shadow: 0 0 2vw #fff, 0 0 3vw #ffd, 0 0 20vw #ffa, 0 0 10vw #ffe, 0 0 0.4vw #e647e6, 0vw 0vw 0vw #ffc; */
  color: #fff;
}

.burnt-grape {
  font-size: 1rem;
  font-family: 'TOMMY-R';
  color: #fff;
}

.burnt-grape-value {
  text-shadow: 0 0 1vw #9f562e, 0 0 3vw #dd6727, 0 0 20vw #7d3711, 0 0 10vw #732d07, 0 0 0.4vw #463024,
    0vw 0vw 0vw #853910;
  color: #e67f47;
  overflow: visible;
}

@media screen and (max-width: 1488px) {
  .front-text-tvl {
    font-size: 5rem;
  }
  .front-text-top {
    font-size: 1.5rem;
  }
  .welcome-text {
    font-size: 3rem;
  }
}

@media screen and (max-width: 1080px) {
  .front-text-tvl {
    font-size: 4.2rem;
  }

  .welcome-text {
    font-size: 2.7rem;
  }
  .burning-grape {
    width: 45px;
    height: 45px;
  }
}

@media screen and (max-width: 900px) {
  .front-text-tvl {
    font-size: 3rem;
  }

  .welcome-text {
    font-size: 2.5rem;
  }
  .burning-grape {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 720px) {
  .front-text-tvl {
    font-size: 2.2rem;
  }

  .welcome-text {
    font-size: 2rem;
  }
}

@media screen and (max-width: 550px) {
  .front-text-tvl {
    font-size: 3.8rem;
  }

  .welcome-text {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 430px) {
  .front-text-tvl {
    font-size: 3.3rem;
  }
}

@media screen and (max-width: 399px) {
  .front-text-tvl {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 359px) {
  .front-text-tvl {
    font-size: 2.8rem;
  }

  .front-text-top {
    font-size: 1.1rem;
  }
}

@keyframes golden-background {
  0% {
    background-image: linear-gradient(to right bottom, #ffd700, #f5ca0a, #eabc12, #dfb016, #d4a31a);
  }
  25% {
    background-image: linear-gradient(to right bottom, #d4a31a, #ffd700, #f5ca0a, #eabc12, #dfb016);
  }
  50% {
    background-image: linear-gradient(to right bottom, #dfb016, #d4a31a, #ffd700, #f5ca0a, #eabc12);
  }
  75% {
    background-image: linear-gradient(to right bottom, #eabc12, #dfb016, #d4a31a, #ffd700, #f5ca0a);
  }
  100% {
    background-image: linear-gradient(to right bottom, #f5ca0a, #eabc12, #dfb016, #d4a31a, #ffd700);
  }
}

@keyframes winepress-background {
  0% {
    background-image: linear-gradient(to right top, #ab17ab, #b91ab9, #c51dc5, #cd1ecd, #db21db);
  }
  25% {
    background-image: linear-gradient(to right top, #db21db, #ab17ab, #b91ab9, #cd1ecd, #cd1ecd);
  }
  50% {
    background-image: linear-gradient(to right top, #cd1ecd, #db21db, #ab17ab, #b91ab9, #db21db);
  }
  75% {
    background-image: linear-gradient(to right top, #b91ab9, #cd1ecd, #db21db, #ab17ab, #db21db);
  }
  100% {
    background-image: linear-gradient(to right top, #ab17ab, #b91ab9, #cd1ecd, #db21db, #ab17ab);
  }
}

.golden {
  color: white !important;
  transform: scale(1);
  background-image: linear-gradient(to right bottom, #ffd700, #f5ca0a, #eabc12, #dfb016, #d4a31a);
}

.mintvintners {
  color: white !important;
  background-image: linear-gradient(to right top, #16e89f, #1ac534, #00ffa3);
}

.winemaker {
  color: white !important;
  background-image: linear-gradient(to left bottom, #018e39, #1ac55e);
}

.winepress {
  color: white !important;
  background-image: linear-gradient(to right top, #810e81, #d01fd0);
}

.get-started {
  animation: winepress-background 1s linear infinite;
}

.pegcampaign {
  color: white !important;
  background-image: linear-gradient(to left top, #1ec38f, #1d8e6a);
}

.shinyButtonDisabled {
  color: #555 !important;
  padding: 7px !important;
  background-color: #641d64 !important;
  font-size: 1rem !important;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 1);
}

.action-button {
  font-size: 0.5rem !important;
  color: white !important;
  font-family: 'TOMMY-R' !important;
  padding: 2px 7px 2px 7px !important;
  background-color: #c61fc6 !important;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 1);
  border: 0 !important;
}
.apr-calc {
  background-color: rgba(155, 155, 155, 0.5) !important;
}

.info-card-price {
  font-size: 16px;
  font-weight: 700;
  color: #fff !important;
}

.card-info-value {
  color: #fff !important;
}

.grey-text {
  color: #929292 !important;
}

.info-card-ratio {
  font-weight: 700;
}

.card-info-text {
  color: #ddd;
}

/*
#930993 light
#72046D dark
#322f32 black/grey
#FFD2D1 background highlight
#D19AFF background
*/

.account {
  display: flex;
  align-items: center;
}

.account > img,
.account > div {
  margin-right: 8px;
}

.shinyButtonDisabledSecondary {
  border-radius: 15px;
  color: grey !important;
  box-shadow: 0px 0px 5px 0px grey;
}

.navTokenPrice {
  padding-top: 5px;
  padding-right: 15px;
  color: #fff;
}

.navTokenIcon {
  height: 30px !important;
  width: 30px !important;
  padding-right: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  overflow: hidden;
}

.MuiPaper-root {
  color: rgb(235, 235, 235) !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

body,
html,
.MuiPaper-root {
  -ms-overflow-style: none !important; /* for Internet Explorer, Edge */
  scrollbar-width: none !important; /* for Firefox */
  overflow-y: scroll !important;
}

.MuiPaper-root::-webkit-scrollbar {
  display: none !important; /* for Chrome, Safari, and Opera */
}
body::-webkit-scrollbar {
  display: none !important; /* for Chrome, Safari, and Opera */
}

html::-webkit-scrollbar {
  display: none !important; /* for Chrome, Safari, and Opera */
}

.MuiAppBar-positionSticky {
  position: relative !important;
}
.ijwpow {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.walletconnecttitle {
  color: #fff;
}
.walletconnecttitle:hover {
  color: #930993;
}
.dropbtn {
  background-color: rgba(0, 0, 0, 0);
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 20px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  border-radius: 10px;
  color: #ccf;
}

.dropdown:hover .dropdown-content {
  display: block;
}
